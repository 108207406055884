import TrazoLogo from "./assets/img/logo.png";
import "./App.css";
import { AiFillLock as LockIcon } from "react-icons/ai";
import { HelmetProvider, Helmet } from "react-helmet-async";

function App() {
  const helmetContext = {};
  setTimeout(() => {
    if (window.location.search?.includes("validar")) {
      const url = window.location.search
        ?.replace("?url=", "")
        ?.replaceAll("%3A", ":")
        ?.replaceAll("%26", "&")
        ?.replaceAll("%3D", "=")
        ?.replaceAll("%2C", ",");

      window.location.replace(url);
    } else {
      const url = window.location.search
        ?.replace("?url=", "")
        ?.replaceAll("%3A", ":")
        ?.replaceAll("%2F", "/")
        ?.replaceAll("%26", "&")
        ?.replaceAll("%3D", "=")
        ?.replaceAll("%2C", ",");

      window.location.replace(url);
    }
  }, 3000);

  return (
    <HelmetProvider context={helmetContext}>
      <div className="App">
        <div className="app-loader">
          <div className="puntos">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="app-loader-text">
            <p>
              <LockIcon /> Con la tecnología de{" "}
              <span>
                <img
                  style={{ verticalAlign: "middle", width: "80px" }}
                  src={TrazoLogo}
                  alt="Trazo Logo"
                />
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* <SEO
        title="Learning React Helmet!"
        description="Beginner friendly page for learning React Helmet."
        name={"Companyname"}
        type={"article"}
      /> */}
    </HelmetProvider>
  );
}

function SEO({ title, description, name, type }) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
}
export default App;
